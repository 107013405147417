.artificial-main-container {
  padding: 0px 15px 30px 15px;
  height: 100%;
  color: #fff;
}
.artificial-heading {
  color: #fff;
}
.artificial-container {
  display: flex;
  flex-direction: row;
}
.artificial-container p {
  padding: 5px 10px;
}
.artificial-img {
  width: 30%;
  border-radius: 15px;
}


@media (min-width: 768px) and (max-width: 1024px)  {
  .artificial-main-container {
    padding-top: 50px;
    height: 100%;
  }
  .artificial-container {
    flex-direction: column;
    align-items: center;
  }
  .artificial-img {
    order: 0;
    width: 60%;
  }
  .artificial-container p {
    order: 1;
  }
}
@media (min-width: 1366px) {
  .artificial-main-container {
    padding-top: 70px;
    height: 100%;
  }
}
@media (max-width:768px) {
  .artificial-main-container {
    padding-top: 20px;
    height: 100%;
  }
  .artificial-container {
    flex-direction: column;
    align-items: center;
  }
  .artificial-img {
    order: 0;
    width: 60%;
  }
  .artificial-container p {
    order: 1;
  }
} 
