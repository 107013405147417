.main-home-container {
  background-image: url("../../Assets/welcomeAi.jpg");
  background-size: cover;
  height: 100vh;
  display: flex;
  align-items: end;
  color: white;
  position: relative;
  background-repeat: no-repeat;
  width: 100%;
}

.heading {
  color: #fff;
}

.home-content {
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.127) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  height: 57%;
  padding: 0px 15px;
  width: 100%;
}

.company-name {
  background-color: aqua;
  border-radius: 5px;
  color: #130133;
  padding: 5px 20px;
  border-bottom: 5px solid orange;
  position: relative;
  display: inline-block;
  overflow: hidden; /* Ensure the animated background stays within bounds */
}

.company-name::before {
  content: '';
  width: 100%;
  height: 100%;
  background-color: orange;
  position: absolute;
  top: 0;
  left: 0;
  animation: animate 5s ease alternate 1 forwards;
  border-right: 5px solid #130133;
  transform-origin: left; /* Ensure the animation starts from the left side */
}

@keyframes animate {
  0% {
    transform: scaleX(1);
    color: #fff;
  }
  50% {
    transform: scaleX(0);
    color: #fff;
  }
  51% {
    transform: scaleX(0);
    background-color: transparent;
  }
  100% {
    transform: scaleX(1);
    background-color: transparent;
  }
}

/* Paragraph container and animation */
.para-container {
  position: relative;
  height: 150px; 
  padding-bottom: 30px;
}

.para {
  position: absolute;
  width: 100%;
  opacity: 0;
  transform: translateY(20px);
  transition: all 1s ease-in-out;
}

.para.show {
  opacity: 1;
  transform: translateY(0);
}

.para.hide {
  opacity: 0;
  transform: translateY(20px);
}

.end {
  margin-bottom: 80px;
}

@media (max-width: 768px) {
  .main-home-container {
    background-image: url("../../Assets/welcomeAiSm.jpg");
  }
}

@media (min-width: 800px) {
  .para-container {
    position: static; /* Reset position */
    height: auto; /* Adjust height automatically */
    display: flex;
    flex-direction: column;
  }

  .para {
    position: relative; /* Reset position */
    opacity: 1; /* Show all paragraphs */
    transform: none; /* Reset transformation */
    transition: none; /* Remove transition */
    margin-top: 2px;
  }
}
