.industry-main-container {
  padding: 50px 25px;
  height: 100%;
  margin-bottom: 20px;
  color: black;
  background-color: #fff;
}
.industry-main-container h1 {
  color: black;
}
.bank-main-container-drop,
.ecom-main-container-drop,
.health-main-container-drop,
.hotel-main-container-drop {
  padding: 20px 10px;
}
.industry-item {
  box-shadow: 0px 2px 6px rgb(244, 212, 154);
  padding: 0px 20px;
  margin-bottom: 12px;
  border-radius: 10px;
  /* animation: CSSscrollReveal ease-in-out both;
  animation-timeline: view();
  animation-range: entry 50% cover 50%; */
}
.industry-item-b {
  box-shadow: 0px 2px 6px #d5c0fa;
  padding: 0px 20px;
  margin-bottom: 12px;
  border-radius: 10px;
  /* animation: CSSscrollReveal ease-in-out both;
  animation-timeline: view();
  animation-range: entry 50% cover 50%; */
}
/* @keyframes CSSscrollReveal {
  from {
    opacity: 0.8;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
} */
.drop-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
