.joinourteam-main-container {
  background-image: url("../../../Assets/joinOurTeamlarge.jpg");
  background-size: cover;
  height: 30vh;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.join-heading {
  font-weight: bold;
  color: white;
  font-size: 50px;
  text-shadow: 2px 2px #020530;
}
@media only screen and (max-width: 700px) {
  .joinourteam-main-container {
    background-image: url("../../../Assets/joinOurTeamMid.jpg");
  }
  .join-heading {
    font-weight: bold;
    font-size: 40px;
  }
}
