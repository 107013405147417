.services-main-container {
  padding: 20px 20px 50px 20px;
  /* background-image: linear-gradient(
    to bottom right,
    rgb(3, 24, 65),
    rgb(241, 103, 17)
  ); */
  background-size: cover;
  height: 100%;
  color: black;
  margin: 0 auto;
}

.heading {
  color: #fff;
}
.slider {
  padding: 20px;
  gap: 10px;
  /* animation: CSSscrollReveal ease-in-out both;
  animation-timeline: view();
  animation-range: entry 50% cover 50%; */
}

/* @keyframes CSSscrollReveal {
  from {
    opacity: 0.8;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
} */
.service-item {
  border-radius: 15px;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  align-content: flex-end;
  height: 300px;
  padding:20px ;
  margin: 0 10px; 

  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}
.service-item:hover{
  transform: scale(0.9);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.services-content {
  background-color: #fff;
  height: 35%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  width: 100%;
  margin: 0px;
  padding: 10px;
  border-radius: 15px;
}
.service-link{
  text-decoration: none;
  color: black;
}
.item-1 {
  background-image: url("../../Assets/cloud.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.item-2 {
  background-image: url("../../Assets/dataAna.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.item-3 {
  background-image: url("../../Assets/ArtificialAndAuto.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.slick-slide {
  transition: transform 0.3s ease-in-out;
  margin-right: 10px;
}

.slick-track {
  display: flex;
  align-items: center;
}

.slick-prev,
.slick-next {
  z-index: 1;
}

@media (max-width: 1024px) {
  .slick-slide {
    transform: translateX(0%);
  }
}

@media (max-width: 768px) {
  .slick-slide {
    transform: translateX(-15%);
  }
  .services-content h3{
    font-size: 12px;

  }
  .service-item:hover{
    transform: none;
  }
  .services-content p{
    font-size: 10px;
  }
  .slick-list{
    border-radius: 15px;
  }
}

@media (max-width: 480px) { 
  .slick-slide {
    transform: translateX(-15%);
  }
}
