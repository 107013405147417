.data-main-container {
  padding: 0px 15px 30px 15px;
  height: 70vh;
  color: #fff;
}

.data-heading {
  color: #fff;
}

.data-container {
  display: flex;
  flex-direction: row;
}

.data-container p {
  padding: 5px 10px;
}

.data-img {
  width: 30%;
  border-radius: 15px;
}
@media (min-width: 768px) and (max-width: 1024px)  {
  .data-main-container {
    padding-top: 50px;
    height: 100%;
  }
  .data-container {
    flex-direction: column;
    align-items: center;
  }
  .data-img {
    order: 0;
    width: 60%;
  }
  .data-container p {
    order: 1;
  }
}
@media (min-width: 1366px) {
  .data-main-container {
    padding-top: 70px;
    height: 100%;
  }
}
@media (max-width:768px) {
  .data-main-container {
    padding-top: 20px;
    height: 100%;
  }
  .data-container {
    flex-direction: column;
    align-items: center;
  }
  .data-img {
    order: 0;
    width: 60%;
  }
  .data-container p {
    order: 1;
  }
}


