.health-main-container {
  padding: 10px 15px 30px 15px;
  height: 75%;
  background-color: #fff;

}
.health-content{
  display: flex;
  align-items: center;
  gap: 15px;
  background-color: rgb(243, 243, 243);
  padding: 15px;
  border-left: 5px solid rgb(3, 24, 65);
  border-radius: 15px;
  margin-top: 15px;
}

.health-img{
  border-radius: 15px;
  width: 25%;
}
.health-list-items {
  padding: 0px 10px;
}
.health-list-items li {
  list-style: none;
  margin-bottom: 20px;
}
.sm-heading{
  display: none;
}
.lg-heading{
  display: block;
}
.health-list-items li span {
  font-weight: bold;
}
@media (min-width: 768px) and (max-width: 1024px)  {
  .health-main-container {
    padding-top: 35px;
  }
}
@media (min-width: 820px) {
  .health-main-container {
    padding-top: 70px;
    height: 50vh;
  }
}
@media (min-width: 1180px) {
  .health-main-container {
    padding-top: 40px;
    height: 100%;
  }
}

/* @media (min-width: 1024px) {
  .health-main-container {
    padding-top: 70px;
    height: 60vh;
  }
} */

@media (min-width: 820px)and (max-width: 1180px){
  .health-main-container{
    height: 100%;
  }
}
@media (max-width: 700px){
  .health-content{
    flex-direction: column;
  }
  .lg-heading{
    display: none;
  }
  .sm-heading{
    display: block;
  }
  .health-img{
    width:65% ;
  }
}