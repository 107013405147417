.form-container {
    background-image: linear-gradient(
      to bottom right,
      rgb(3, 24, 65),
      rgb(241, 103, 17)
    );
    width:90%;
    max-width: 500px;
    margin: 40px auto;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  .application-form {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 15px;
    border-radius: 15px;
  }
  .Apply-heading{
    color: #fff;
  }
  .application-form h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .application-form label {
    margin-bottom: 5px;
    color: #555;
    font-weight: bold;
  }
  
  .application-form input[type="text"],
  .application-form input[type="email"],
  .application-form input[type="tel"],
  .application-form textarea,
  .application-form input[type="file"] {
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    color: #333;
    width: 100%;
  }
  
  .application-form textarea {
    resize: vertical;
    height: 100px;
  }
  
  .resume-label {
    margin-bottom: 10px;
  }
  
  .application-form button {
    padding: 10px;
    background-color: #130133;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .application-form button:hover {
    background-color:rgb(241, 103, 17);
  }
  
  @media (max-width: 768px) {
    .form-container {
      padding: 10px;
    }
  }
  