.main-footer-container {
  background-color: #130133;
  background-size: cover;
  margin: 0;
  padding: 30px 15px;
  bottom:0;
  display: flex;
  flex-direction: column;

}
.footer-logo-content-container{
  display: flex;
  align-items: center;
  justify-content: start;
  color: #fff;
  gap: 10px;
}
.CompanyImage{
  width: 50px;
  border-radius: 50%;
}
@media (max-width: 768px){
/* .CompanyImage{
  width: 30px;
} */
}
.footer-company-name{
  font-size:17px;
}
.footer-content-items {
  color: black;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
}
.footer-content-items div h3 {
  color: #fff;
  margin-bottom: 20px;
  font-size: 20px;
}
.contact-icons-container {
  display: flex;
  align-items: center;
  gap: 5px;
}
.contact-icons-container a {
  color: #fff;
}
ul {
  padding: 0;
}
.footer-item {
  list-style: none;
  text-decoration: none;
  color: #fff;
  padding: 5px;
}


.footer-terms-content {
  text-align: center;
  color: #fff;
}
.footer-terms-links {
  display: flex;
  justify-content: center;
  gap: 10px;
  list-style: none;
}
.visit-address-container{
  margin: 10px 0px;
  /* display: flex;
  flex-direction: column;
  gap: 5px; */
}
.visit-address{
  color: #fff;
  font-size: 12px;
  text-decoration: none;
}
.copy{
  font-size: 10px;
}
@media (max-width: 768px) {
  .CompanyImage{
    width: 35px;
  }
  .footer-content-items {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .footer-content-items div h3{
    font-size: 19px;
    margin-bottom: 15px;
  }
  .footer-item{
    padding: 2px;
    font-size: 12px;
  }
  .visit-address{
    font-size: 12px;
  }
}
@media (max-width: 480px) {
    .footer-content-items {
      grid-template-columns: 1fr 1fr;
    }
    
  }
