.main-booking-container {
  background-image: url("../../Assets/BookAp.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  height: 100%;
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.appointment-main-container {
  display: flex;
  justify-content: space-around;
  gap: 20px;
  padding: 0px 15px 30px 15px;
}
.book-content {
  /* color: #fff; */
  border-left: 5px solid rgb(241, 103, 17);
  border-radius: 15px;
  background-color: rgba(243, 243, 243, 0.744);
  height: fit-content;
  width: 50%;
  padding: 10px;
}
.book-heading {
  font-size: 50px;
  color: #fff;
  font-weight: bold;
  margin-top: 130px;
  text-shadow: 2px 2px #020530;
}

.form-container-booking {
  background-image: linear-gradient(
    to bottom right,
    rgb(3, 24, 65),
    rgb(241, 103, 17)
  );
  padding: 10px 15px;
  width: 80%;
  border-radius: 15px;
}
.form-appointment {
  background: #fff;
  padding: 13px;
  border-radius: 15px;
}
.loading-indicator {
  display: flex;
  justify-content: center;
  gap: 10px;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}

.loading-indicator img {
  width: 50px;
  height: 50px;
}
.appointmentsubmit-button {
  background-color: #130133;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  color: #fff;
  outline: none;
  border: none;
}
.appointmentsubmit-button:hover {
  background-color: rgb(241, 103, 17);
}
.custom-dropdown {
  position: relative;
  width: 100%;
  cursor: pointer;
}
.custum-input {
  position: relative;
  box-sizing: border-box;
}

.custom-dropdown-selected {
  padding: 10px;
  color: black;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
}

.custom-dropdown-options {
  position: absolute;
  color: black;
  top: 100%;
  left: 0;
  right: 0;
  margin: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  list-style: none;
  border-radius: 5px;
  z-index: 1000;
}

.custom-dropdown-option {
  padding: 10px;
  border-bottom: 1px solid #eee;
}

.custom-dropdown-option:hover {
  background-color: #f0f0f0;
  border-left: 3px solid #ff610b;
  color: black;
}

.custom-dropdown-option:last-child {
  border-bottom: none;
}

@media only screen and (max-width: 700px) {
  .appointment-main-container {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  .form-container-booking{
    width: 90%;
  }
  .book-content {
    width: 90%;
  }
  .book-heading {
    font-weight: bold;
    font-size: 35px;
  }
}
