.about-main-container {
  padding: 0px 25px 50px 25px;
  background-size: cover;
  height: 100%;
}
.heading {
  color: #fff;
}
.about-item {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  align-items: flex-start;
  border-radius: 15px;
  margin: 40px 0px;
  padding: 10px;
  box-shadow: 2px 0px 9px 1px rgba(0, 0, 0, 0.2);
  border-left: 5px solid rgb(241, 103, 17);
  background-color: #fff;
}

.sub-heading {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
  color: #000;
}

.vision-card {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  align-items: flex-start;
  border-radius: 15px;
  margin: 10px 0px;
  padding: 10px;
  box-shadow: 2px 0px 9px 1px rgba(0, 0, 0, 0.2);
  border-right: 5px solid rgb(3, 24, 65);
  background-color: #fff;
}
.value-item {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  align-items: flex-start;
  border-radius: 15px;
  margin: 40px 0px;
  padding: 0;
  box-shadow: 2px 0px 9px 1px rgba(0, 0, 0, 0.2);
  border-left: 5px solid rgb(241, 103, 17);
  background-color: #fff;
}
/* .about-item,
.vision-card,
.value-item {
  animation: CSSscrollReveal ease-in-out both;
  animation-timeline: view();
  animation-range: entry 50% cover 50%;
}
@keyframes CSSscrollReveal {
  from {
    opacity: 0.7;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
} */
.about-content {
  padding: 0px 20px;
}
.about-content h2 {
  margin: 5px 0px;
}
.about-content p {
  padding: 15px 20px;
}
.about-img {
  width: 30%;
  height: 30%;
  border-radius: 15px;
}
.vision-img {
  width: 30%;
  height: 30%;
  border-radius: 15px;
}

.innovation-img,
.integrity-img {
  width: 30%;
  height: 30%;
  border-radius: 15px;
}
.value-1 {
  display: flex;
  align-items: center;
}
.value-2 {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}


@media only screen and (min-width: 1024px) and (max-width: 1366px) {
  .about-main-container{
    padding-top: 100px;
  }

}
@media only screen and (max-width: 700px) {
  .about-img,
  .vision-img,
  .innovation-img,
  .integrity-img {
    display: none;
  }
}
