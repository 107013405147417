.policy-container {
  padding: 30px 15px 20px 15px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  color: #333;
}
h1 {
  color: #000;
}
.section {
  margin-bottom: 20px;
}

.sub-heading {
  font-weight: bold;
  display: block;
  margin-bottom: 10px;
  color: #000;
}

p {
  margin: 0 0 10px 0;
  line-height: 1.6;
}

.policy-ul {
  margin: 10px 0;
  padding-left: 20px;
}

.policy-ul li {
  margin-bottom: 5px;
}
