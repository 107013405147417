/* Main navigation container */
.main-nav-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #130133; /* Dark gray background */
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  padding: 10px 20px;
  position: fixed;
  width: 100%;
  z-index: 1;
  height: 10%;
}
.logo-content-container{
  display: flex;
  align-items: center;
  justify-content: end;

  gap: 10px;
}
.company-slog{
  font-size: 10px;
  font-weight: bold;
}
.header-company-name{
  font-size:17px;
}
.company-slog, .header-company-name{
  margin: 0;
  /* cursor: pointer; */
  color: #fff;
}
.link{
  text-decoration: none;
  color: white;
}
.header-icon {
  width: 50px;
  height: auto;
  /* border: 3px solid #800080; */
  border-radius: 50%;
}


/* Navigation items styling */
.nav-items {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 5px;
  margin-right: 20px;
}

.nav-items .item {
  position: relative;
  padding: 5px 10px;
  color: #fff; /* White text for contrast */
  cursor: pointer;
  text-align: center; /* Center text */
  margin-right: 20px;
  margin-bottom: 0px;
}

.dropdown{
  display: flex; 
  align-items: center;
  justify-content: center;
}

/* Dropdown menu styling */
.dropdown-menu {
  display: none; /* Hidden by default */
  position: absolute;
  top: 100%; 
  left: 0;
  background-color: #f9f9f9; /* Dark gray for dropdown background */
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding:0;
  margin: 0;
  opacity: 0.9;
  min-width: fit-content; /* Adjust width to fit content */
  white-space: nowrap; /* Prevent text wrapping */
}

.drop-li {
  color: #000; /* White text */
  text-align: left; /* Center text */
  list-style: none;
  margin: 5px;
  padding: 5px;
}
/* Additional styling for navigation items */
.nav-items .item:hover {
  background-color: #ff610b; /* Purple hover effect */
  border-radius: 5px;
  /* opacity: 0.5; */
}

.drop-li:hover {
  border-left: 3px solid #ff610b;
}

/* Show dropdown menu on hover */
.dropdown:hover .dropdown-menu {
  display: block;
}

/* Menu icon */

.menu-icon {
  display: none;
}

/* mediaq */
@media only screen and (min-width: 820px) and (max-width: 1180px){
  .item{
    font-size: small;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1366px){
  .item{
    font-size:large;
  }
}
@media only screen and (max-width: 800px) {
  .main-nav-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
  }
  .header-icon {
    width: 35px;
  }
  .image-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .nav-items .item{
    padding: 15px 0px;
    font-size: 20px;
    margin-right: 0px;
  }
  .nav-items .item:hover {
    background-color: #ff600bb6;
  }
  .nav {
    position: absolute;
    top: 75px;
    background-color: #030f3bed;
    width: 100%;
  }
  
  .nav-items {
    flex-direction: column;
    position: relative;
  }
  .open-nav-item {
    display: none;
  }
  .close-nav-item {
    display: block;
  }
  .menu-icon {
    display: block;
    color: white;
    font-size: xx-large;
    font-weight: bold;
    margin-right: 25px;
  }
}
