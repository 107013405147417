.outlets-container {
  padding: 88px 0px 0px 0px;
  margin: 0;
  box-sizing: border-box;
  background-image: linear-gradient(
    to bottom right,
    rgb(3, 24, 65),
    rgb(241, 103, 17)
  );
}
* {
  font-family: sans-serif;
}
/*  */
.body-main-container {
  height: 100vh;
}
@media (max-width: 768px) {
  .body-main-container {
    height: 100%;
  }
}
