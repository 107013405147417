.why-join-us {
  background-color: #fff;
  height: fit-content;
  color: black;
  width: 100%;
}
.why-content-container {
  display: flex;
  align-items: flex-start;
  padding: 0px 20px;
}
.why-bgimg {
  background-image: url("../../Assets/WhyJoinUs.jpg");
  background-size: cover;
  background-repeat: no-repeat; 
  background-position: center; 
  background-attachment: fixed; 
  height: 30vh;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.why-bgimg h1 {
  color: #fff;
  text-shadow: 2px 2px #020530;
  margin-top: 0px;
  font-size: 50px;

}
.section-orange {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 15px;
  padding: 15px;
  box-shadow: 0px 2px 6px rgb(244, 212, 154);
  animation: CSSscrollReveal ease-in-out both;
  animation-timeline: view();
  animation-range: entry 50% cover 50%;
}
.section-blue {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 15px;
  padding: 15px;
  box-shadow: 0px 2px 6px #d5c0fa;
  animation: CSSscrollReveal ease-in-out both;
  animation-timeline: view();
  animation-range: entry 50% cover 50%;
}
@keyframes CSSscrollReveal {
  from {
    opacity: 0.8;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}
.section-img {
  width: 100px;
  padding: 0px 20px;
}

.sub-heading {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
  color: #000;
}

p {
  margin: 0;
  line-height: 1.5;
}
.why-content {
  padding: 40px 10px;
}

@media (max-width: 480px) { 
  .section-orange{
    flex-direction: column-reverse;
  }
  .section-blue{
    flex-direction: column;
    align-items: center;
  }
  .why-bgimg {
    background-size: cover;
  }
  .why-bgimg h1{
    font-size: 40px;
  }
}


