.bank-main-container {
  padding: 10px 15px 30px 15px;
  height: 75%;
  background-color: #fff;
}
.banking-content{
  display: flex;
  align-items: center;
  gap: 15px;
  background-color: rgb(243, 243, 243);
  padding: 15px;
  border-left: 5px solid rgb(241, 103, 17);
  border-radius: 15px;
  margin-top: 15px;
}

.banking-img{
  border-radius: 15px;
  width: 25%;
}
.bank-list-items {
  padding: 0px 10px;
}
.bank-list-items li {
  list-style: none;
  margin-bottom: 20px;
}
.sm-heading{
  display: none;
}
.lg-heading{
  display: block;
}
.bank-list-items li span {
  font-weight: bold;
}
@media (min-width: 768px) and (max-width: 1024px)  {
  .bank-main-container {
    padding-top: 35px;
  }
}
@media (min-width: 820px) {
  .bank-main-container {
    padding-top: 70px;
    /* height: 50vh; */
  }
}
@media (min-width: 1180px) {
  .bank-main-container {
    padding-top: 40px; 
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .bank-main-container {
    padding-top: 70px;
    height: 80vh;
    margin-bottom: 50px;
  }
}

@media (min-width: 820px)and (max-width: 1180px){
  .bank-main-container{
    height: 65vh;
  }
}
@media (max-width: 700px){
  .banking-content{
    flex-direction: column;
  }
  .lg-heading{
    display: none;
  }
  .sm-heading{
    display: block;
  }
  .banking-img{
    width:65% ;
  }
}