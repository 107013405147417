.cloud-main-container {
  padding: 0px 15px 30px 15px;
  height: 70vh;
  color: #fff;
}
.cloud-heading {
  color: #fff;
}
.cloud-container {
  display: flex;
  flex-direction: row;
}
.cloud-container p {
  padding: 5px 10px;
}
.cloud-img {
  width: 30%;
  border-radius: 15px;

}


 /*  */

 @media (min-width: 768px) and (max-width: 1024px)  {
  .cloud-main-container {
    padding-top: 50px;
    height: 100%;
  }
  .cloud-container {
    flex-direction: column;
    align-items: center;
  }
  .cloud-img {
    order: 0;
    width: 60%;
  }
  .cloud-container p {
    order: 1;
  }
}
@media (min-width: 1366px) {
  .cloud-main-container {
    padding-top: 70px;
    height: 100%;
  }
}
@media (max-width:768px) {
  .cloud-main-container {
    padding-top: 20px;
    height: 100%;
  }
  .cloud-container {
    flex-direction: column;
    align-items: center;
  }
  .cloud-img {
    order: 0;
    width: 60%;
  }
  .cloud-container p {
    order: 1;
  }
}
