.contact-form-container {
  padding: 20px 15px 30px 15px;
  /* background-color: white; */
}
.contact-bg-container {
  background-image: url("../../Assets/contact.jpg");
  background-size: cover;
  background-repeat: no-repeat; /* Prevent the image from repeating */
  background-position: center; /* Center the image */
  background-attachment: fixed; /* Make the background fixed on scroll */
  height: 30vh;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.contact-heading {
  font-size: 50px;
  color: #fff;
  font-weight: bold;
  text-shadow: 2px 2px #020530;
}
.visit-heading ,.contact-sub-heading,.contact-content{
  color: #fff;
}
.link-book {
  box-sizing: border-box;
  color: #fff;
  padding: 7px 15px;
  border-radius: 10px;
  font-weight: bold;
  margin-bottom: 5px;
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.link-book:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}
/* blink-css */
.blink {
  background-image: linear-gradient(
    to right,
    rgb(3, 24, 65),
    rgb(241, 103, 17)
  );
  animation: gradient-animation 3s ease infinite;
  background-size: 200% 200%;
  padding: 10px 15px;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.contact-details-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.form-wrapper {
  width: 50%;
  margin: 20px;
  padding: 20px;
  /* background-color: #fff; */
  border-radius: 8px;
}
.form-content {
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
}
.map-container {
  width: 50%;
}
iframe {
  width: 100%;
  height: 90%;
}
label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

input,
textarea {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.contact-button {
  background-color: #130133;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
}

.contact-button:hover {
  background-color:rgb(241, 103, 17);
}
@media only screen and (max-width: 700px) {
  .contact-heading {
    font-size: 40px;
  }
  .contact-details-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  .react-tel-input .form-control{
    width: 100%;
  }
  .form-wrapper {
    width: 90%;
  }
  .map-container {
    width: 90%;
    border-radius: 15px;
  }
}

